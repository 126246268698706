import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { zipCityMessages } from '@tk/utilities/tk.messages';

interface ZipCityData {
    zip: string;
    city: string;
    country: string;
}

interface ZipCityField {
    inputField?: HTMLInputElement;
    dropdown?: HTMLElement;
    listItemSelected?: HTMLElement;
    data?: ZipCityData[];
}

export default class TKFormZipCity extends TKCustomElementFactory {
    fieldCountry?: HTMLInputElement | HTMLSelectElement;
    fieldZip?: HTMLInputElement;
    fieldCity?: HTMLInputElement;
    inputObject: ZipCityField;
    selectedField?: HTMLInputElement;
    listWrapper: HTMLElement;
    timer?: ReturnType<typeof setTimeout>;
    lastSearchTerm: string;
    listWrapperClassName: string;
    loadingSpinnerTimeout: number | null;
    clearDataTimeout: number | null;

    constructor() {
        super();

        this.listWrapperClassName = this.getAttribute('data-tk-zip-list-class') || 'tk-form-zipcity__list';
        this.fieldCountry = this.querySelector('[data-tk-zip-country]') || undefined;
        this.fieldZip = this.querySelector('[data-tk-zip-zip]') || undefined;
        this.fieldCity = this.querySelector('[data-tk-zip-city]') || undefined;
        this.lastSearchTerm = '';
        this.selectedField = undefined;
        this.listWrapper = document.createElement('div');
        this.listWrapper.classList.add(this.listWrapperClassName);
        this.listWrapper.hidden = true;
        this.loadingSpinnerTimeout = null;
        this.clearDataTimeout = null;

        this.inputObject = {
            inputField: undefined,
            dropdown: this.listWrapper,
            listItemSelected: undefined,
            data: [],
        };
    }

    connectedCallback(): void {
        this.registerListener();
    }

    registerListener() {
        if (!this.fieldZip && !this.fieldCity) return;

        const onInputHandler = this.searchZipCity.bind(this);
        const onClickHandler = this.toggleDropdown.bind(this);
        const onSelectHandler = this.selectItem.bind(this);
        const onChangeHandler = this.clearFormFields.bind(this);
        const onInputKeyNavigationHandler = this.inputKeyNavigation.bind(this);
        const onDropdownKeyNavigationHandler = this.dropdownKeyNavigation.bind(this);

        this.pushListener({ event: 'click', element: this.fieldZip!, action: onClickHandler });
        this.pushListener({ event: 'click', element: this.fieldCity!, action: onClickHandler });
        this.pushListener({ event: 'click', element: this.inputObject.dropdown!, action: onSelectHandler });
        this.pushListener({ event: 'input', element: this.fieldZip!, action: onInputHandler });
        this.pushListener({ event: 'input', element: this.fieldCity!, action: onInputHandler });
        this.pushListener({ event: 'keydown', element: this.fieldZip!, action: onInputKeyNavigationHandler });
        this.pushListener({ event: 'keydown', element: this.fieldCity!, action: onInputKeyNavigationHandler });
        this.pushListener({
            event: 'keydown',
            element: this.inputObject.dropdown!,
            action: onDropdownKeyNavigationHandler,
        });

        if (this.fieldCountry) {
            this.pushListener({ event: 'change', element: this.fieldCountry, action: onChangeHandler });
        }
    }

    inputKeyNavigation(event: KeyboardEvent): void {
        if (!(event.code === 'Tab' || event.code === 'Enter' || event.code === 'ArrowDown')) return;

        this.inputObject.listItemSelected = this.inputObject.dropdown?.firstElementChild as HTMLElement;

        if (!this.inputObject.listItemSelected) return;

        this.inputObject.dropdown?.classList.remove('hide');
        this.inputObject.listItemSelected.focus();
        event.preventDefault();
    }

    dropdownKeyNavigation(event: KeyboardEvent): void {
        const keyActions: Record<string, () => void> = {
            ArrowDown: () => this.handleArrowKey(true),
            ArrowUp: () => this.handleArrowKey(false),
            Enter: () => this.selectItem(event),
            Space: () => this.selectItem(event),
            Tab: () => this.hideDropdown(true),
            Escape: () => this.hideDropdown(true),
        };

        const action = keyActions[event.code];
        if (!action) return;

        action();
        event.preventDefault();
    }

    handleArrowKey(isDown: boolean): void {
        if (this.inputObject.listItemSelected) {
            const nextElement = isDown
                ? this.inputObject.listItemSelected.nextElementSibling
                : this.inputObject.listItemSelected.previousElementSibling;

            if (nextElement instanceof HTMLElement) {
                this.inputObject.listItemSelected = nextElement;
            }
        } else {
            this.inputObject.listItemSelected = this.inputObject.dropdown?.firstElementChild as HTMLElement;
        }

        if (!this.inputObject.listItemSelected) {
            return;
        }

        this.inputObject.listItemSelected.focus();
        this.setScroller();
    }

    hideDropdown(isHidden: boolean = false): void {
        if (this.inputObject.dropdown) {
            if (!isHidden) {
                this.showLoadingAnimation();
                this.listWrapper.style.border = 'unset';
            }
            this.inputObject.dropdown.hidden = isHidden;
        }
    }

    toggleDropdown() {
        if (this.inputObject.dropdown && this.inputObject.dropdown.hasChildNodes()) {
            this.inputObject.dropdown.hidden = !this.inputObject.dropdown.hidden;
        }
    }

    setScroller(): void {
        const { dropdown, listItemSelected } = this.inputObject;

        if (!dropdown || !listItemSelected) return;

        const index = Array.from(dropdown.children).indexOf(listItemSelected);
        const elHeight = listItemSelected.offsetHeight;
        const { scrollTop } = dropdown;
        const viewport = scrollTop + dropdown.offsetHeight;
        const elOffset = elHeight * index;

        if (elOffset >= scrollTop && (elOffset + elHeight) <= viewport) return;

        dropdown.scrollTop = elOffset;
    }

    setInputObject(): void {
        if (!this.selectedField?.parentElement?.querySelector(`.${this.listWrapperClassName}`)) {
            this.selectedField?.insertAdjacentElement('afterend', this.listWrapper);
        }

        this.inputObject.inputField = this.selectedField;
    }

    searchZipCity(event: Event): void {
        this.selectedField = event.target as HTMLInputElement;
        const value = this.selectedField!.value.trim();

        if (value.length < 2) {
            this.clearDataTimeout && clearTimeout(this.clearDataTimeout);
            this.clearDataTimeout = setTimeout(() => {
                this.clearData();
            }, 300);
            return;
        }

        this.lastSearchTerm = value;

        this.setInputObject();
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.callApi(value);
        }, 300);
    }

    callApi(value: string) {
        if (!this.inputObject.dropdown) return;
        this.hideDropdown(false);

        if (this.lastSearchTerm !== value) return;

        const zipCity = this.parseZipCity(value);
        const apiUrl = `${window.location.origin}/api/portal/v1/zipsearch`;

        const urlObject = new URL(apiUrl);
        const { searchParams } = urlObject;

        Object.entries(zipCity).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        fetchRequest({
            requestURL: urlObject.toString(),
            resolveHandler: this.refreshResultList.bind(this),
            decodeJSONResponse: false,
        });
    }

    parseZipCity(value: string) {
        let zip: string | number;
        let city: string;

        const country: string = this.fieldCountry?.value?.toLowerCase() || 'ch';

        const arr = value.split(' ');
        const numberRegex = /^[0-9]+$/;
        const regexTest = arr.find((elem) => numberRegex.test(elem));
        if (regexTest === undefined) {
            zip = '';
        } else {
            zip = regexTest;
        }

        if (!zip) {
            if (arr.length > 1) {
                zip = +arr[1] || '';
            }
        }

        zip = zip.toString();
        city = value.replace(zip, '').trim();
        if (city === 'undefined') {
            city = '';
        }

        return {
            zip,
            city,
            country,
        };
    }

    refreshResultList(response: TKResponse): void {
        if (!response) return;
        this.clearData();
        if (Array.isArray(response)) {
            response.forEach((element: ZipCityData) => {
                const listItemTpl: string = `
                <button type="button" class="tk-form-list-item"
                    data-tk-country="${element.country}"
                    data-tk-zip="${element.zip}"
                    data-tk-city="${element.city}"
                    tabindex="-1"
                >
                    <span class="tk-form__label">
                        ${element.zip} ${element.city}
                    </span>
                </button>`;

                this.inputObject.dropdown!.insertAdjacentHTML('beforeend', listItemTpl);
                this.inputObject.data?.push({ zip: element.zip, city: element.city, country: element.country });
            });
        }

        if (this.inputObject.data!.length === 0) {
            const listItemTpl: string = `
                <div class="spacer-p-2">
                    <div class="tk-message tk-message--info tk-message--fullwidth">
                        <span class="tk-message__icon">
                            <i class="tk-icon-warning"></i>
                        </span>
                        <span class="tk-message__label">${zipCityMessages.noMatchFound}</span>
                    </div>
                </div>`;

            this.inputObject.dropdown!.insertAdjacentHTML('beforeend', listItemTpl);
        }
        this.listWrapper.style.border = '';
        this.loadingSpinnerTimeout && clearTimeout(this.loadingSpinnerTimeout);
        this.inputObject.dropdown!.hidden = false;
    }

    selectItem(event: Event): void {
        let selectedItem: HTMLElement = event.target as HTMLElement;
        selectedItem = selectedItem.closest('[data-tk-zip]') as HTMLElement;

        if (!selectedItem) return;
        const zip = selectedItem.getAttribute('data-tk-zip') as string;
        const city = selectedItem.getAttribute('data-tk-city') as string;
        const country = selectedItem.getAttribute('data-tk-country') as string;

        if (this.fieldZip) {
            this.fieldZip.value = zip;
        }
        if (this.fieldCity) {
            this.fieldCity.value = city;
        }
        if (this.fieldCountry) {
            this.fieldCountry.value = country;
        }

        this.inputObject.inputField!.focus();
        this.clearData();
    }

    clearFormFields(): void {
        this.clearData();
        this.fieldCity!.value = '';
        this.fieldZip!.value = '';
    }

    clearData(): void {
        if (this.inputObject.dropdown) {
            this.hideDropdown(true);
            this.inputObject.dropdown.replaceChildren();
        }
        this.inputObject.data = [];
    }

    showLoadingAnimation() {
        this.loadingSpinnerTimeout && clearTimeout(this.loadingSpinnerTimeout);
        this.loadingSpinnerTimeout = setTimeout(() => {
            this.listWrapper.innerHTML = `
                <div class="tk-progress tk-progress--infinite tk-progress--breakout">
                    <div class="tk-progress__bar"></div>
                </div>`;
        }, 300);
    }

    static async isValidZipCity(form: HTMLFormElement): Promise<boolean> {
        const apiUrl = `${window.location.origin}/api/portal/v1/zipsearch`;
        const zipCityElem = {
            country: form.querySelector('[data-tk-zip-country]') as HTMLInputElement || '',
            zip: form.querySelector('[data-tk-zip-zip]') as HTMLInputElement || '',
            city: form.querySelector('[data-tk-zip-city]') as HTMLInputElement || '',
        };

        const zipCityValue: ZipCityData = {
            country: zipCityElem.country.value,
            zip: zipCityElem.zip.value,
            city: zipCityElem.city.value,
        };

        const urlObject = new URL(apiUrl);
        const { searchParams } = urlObject;

        Object.entries(zipCityValue).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        return new Promise((resolve) => {
            fetchRequest({
                requestURL: urlObject.toString(),
                decodeJSONResponse: false,
                resolveHandler: (response: TKResponse) => {
                    let isValid = false;
                    if (Array.isArray(response)) {
                        response.some((item: ZipCityData) => {
                            isValid = TKFormZipCity.shallowObjectEquality(zipCityValue, item);

                            return isValid;
                        });
                    }
                    resolve(isValid);
                },
            });
        });
    }

    static shallowObjectEquality(object1: ZipCityData, object2: ZipCityData): boolean {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        let isEqual = true;

        if (keys1.length !== keys2.length) {
            isEqual = false;
            return isEqual;
        }

        keys1.forEach((key) => {
            if (object1[key as keyof ZipCityData] !== object2[key as keyof ZipCityData]) {
                isEqual = false;
                return isEqual;
            }
        });
        return isEqual;
    }
}
