import TKManager from '@tk/basiscs/tk.manager';

interface ActionItem {
    id: string;
    affiliation: string;
    revenue: string;
    tax: string;
    shipping: string;
}

interface ProductItem {
    id: string;
    name: string;
    brand: string;
    category: string;
    price: string;
    quantity: string;
    position: string;
}

export default class NSManager extends TKManager {
    constructor() {
        super();
    }

    getPurchaseData() {
        return {
            event: 'event-purchase-ga4',
            ecommerce: {
                purchase: {
                    actionField: this.getActionData(),
                    products: this.getProductData(),
                },
            },
        };
    }

    getActionData() {
        const affiliation = this.querySelector<HTMLElement>('[data-tk-otm-affiliation]');
        const revenue = this.querySelector<HTMLElement>('[data-tk-otm-revenue]');
        const tax = this.querySelector<HTMLElement>('[data-tk-otm-tax]');
        const shipping = this.querySelector<HTMLElement>('[data-tk-otm-shipping]');
        const currency = this.querySelector<HTMLElement>('[data-tk-otm-currency]');
        return {
            id: this.basketId!.innerText,
            affiliation: affiliation ? affiliation.innerText : '',
            revenue: revenue ? revenue.innerText : '',
            tax: tax ? tax.innerText : '',
            shipping: shipping ? shipping.innerText : '',
            currency: currency ? currency.innerText : '',
        } as ActionItem;
    }

    getProductData() {
        const data: ProductItem[] = [];
        const items = this.querySelectorAll<HTMLElement>('[data-tk-otm-item]');
        Array.from(items).forEach((item) => {
            const id = item.querySelector<HTMLElement>('.otm-id');
            const name = item.querySelector<HTMLElement>('.otm-name');
            const brand = item.querySelector<HTMLElement>('.otm-brand');
            const category = item.querySelector<HTMLElement>('.otm-category');
            const price = item.querySelector<HTMLElement>('.otm-price');
            const quantity = item.querySelector<HTMLElement>('.otm-quantity');
            const position = item.querySelector<HTMLElement>('.otm-position');
            data.push({
                id: id ? id.innerText : '',
                name: name ? name.innerText : '',
                brand: brand ? brand.innerText : '',
                category: category ? category.innerText : '',
                price: price ? price.innerText : '',
                quantity: quantity ? quantity.innerText : '',
                position: position ? position.innerText : '',
            });
        });
        return data;
    }
}
