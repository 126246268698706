import TKFormElement from '@tk/controls/tk.form.element';
import { fetchRequest } from '@tk/utilities/tk.fetch';

export default class NSFormElement extends TKFormElement {
    inputTimeout: number | undefined;
    processOnInput: string;

    constructor() {
        super();

        this.processOnInput = this.getAttribute('data-tk-process-on-input') || '';
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.processOnInput) {
            this.registerInputListener();
        }
    }

    registerInputListener() {
        if (!this.fieldElement) return;

        const onInputHandler = this.onInputChanged.bind(this);
        this.pushListener({ event: 'input', element: this.fieldElement, action: onInputHandler });
    }

    onInputChanged() {
        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = window.setTimeout(() => {
            const data = {
                remark: this.fieldElement!.value,
            };
            fetchRequest({
                requestURL: this.processOnInput as string,
                resolveHandler: this.refreshContent.bind(this),
                payload: data,
            });
        }, 500);
    }

    refreshContent() {
        return this;
    }

    /**
     * For the Material Number Input or forms inside of tables, it is necessary that all surrounding elements have been
     * fullyloaded before the Material Number Element itself is displayed.
     * This is because the position of the label in the collapsed state
     * needs to be calculated for the Material Number Input. This calculation depends on the surrounding elements
     */
    onDomContentLoaded() {
        document.addEventListener('DOMContentLoaded', () => {
            this.checkRequiredAttribute();
            this.registerAddonClickedListener();
            this.registerAddonHoverListener();
            this.registerShowPasswordClickedListener();
            this.initMaterialInput();
            this.observeDisabledAttribute();
            if (this.processOnInput) {
                this.registerInputListener();
            }
        });
    }
}
