import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import render from '@tk/utilities/tk.render';
import { fetchRequest } from '@tk/utilities/tk.fetch';

export default class NSImage extends TKCustomElementFactory {
    artId: string;
    url: string | null | undefined;

    constructor() {
        super();
        this.artId = this.getAttribute('data-tk-article-id') || '';
        this.url = document.querySelector('[data-tk-article-image-url]')?.getAttribute('data-op-url');
    }

    connectedCallback(): void {
        if (!this.artId) throw Error('Missing article internal number.');
        if (!this.url) throw Error('Missing URL.');
        const data = { item: this.artId };

        fetchRequest({
            requestURL: this.url,
            resolveHandler: this.renderImage.bind(this),
            payload: data,
        });
    }

    renderImage(response: TKResponse): void {
        if (!response.success || !response.dataAsHtml) return;
        const image = render(response.dataAsHtml);
        this.insertAdjacentElement(
            'beforeend',
            image,
        );
    }
}
