import TKFormValidator from '@tk/controls/tk.form.validator';
import { formExpandErrorMessages } from '@tk/utilities/tk.messages';

interface ValidationResponse {
    isValid: boolean;
}

export default class NSFormValidator extends TKFormValidator {
    ignoreInactiveTabs: boolean;

    constructor() {
        super();
        this.ignoreInactiveTabs = this.hasAttribute('data-tk-ignore-inactive-tabs');
    }

    async validateByAPI(): Promise<boolean> {
        const validationList: boolean[] = [];
        const fieldElements = this.querySelectorAll('[data-tk-field-check-api]');
        if (fieldElements.length === 0) return true;
        const apiPromises = Array.from(fieldElements).map(async (element) => {
            if (this.ignoreInactiveTabs && !element.closest('.tk-tabs-tab--active')) return true;
            const apiURL = element.getAttribute('data-tk-field-check-api');
            const input = element.querySelector('input');
            if (!input || !apiURL) return true;
            const response = await TKFormValidator.fetchExistAPI<ValidationResponse>(apiURL, input.value);
            const validationItem = this.validationList.find((item) => item.wrapper === element);
            const { isValid } = response.dataAsJson;
            if (!isValid && validationItem) {
                this.removeMessage(validationItem);
                this.createElementMessage(
                    validationItem,
                    formExpandErrorMessages.existigName,
                );
            }
            return isValid;
        });
        const results = await Promise.all(apiPromises);
        validationList.push(...results);
        return validationList.every((item) => item);
    }
}
