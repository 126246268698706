import TKModuleFactory from '@tk/utilities/tk.module.factory';
import TKHeader from '@tk/components/tk.header';
import TKNavigationSidebar from '@tk/components/tk.navigation.sidebar';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKSearch from '@tk/controls/tk.search';
import TKFormZipCity from '@tk/controls/tk.form.zipcity';
import TKTabsTab from '@tk/controls/tk.tabs.tab';
import TKMinibasket from '@tk/components/tk.minibasket';
import TKTabsAccordion from '@tk/controls/tk.tabs.accordion';
// NS-53: Compare f�r Phase 1 auskommentiert
// import TKCompareListLink from '@tk/components/tk.compare.list.link';
import TKDialog from '@tk/components/tk.dialog';
import NSManager from '@/basics/ns.manager';
import NSFormElement from '@/controls/ns.form.element';
import NSFormValidator from '@/controls/ns.form.validator';
import NSImage from '@/components/ns.image';

import '@scss/modules/verification.scss';

export default class Verification extends TKModuleFactory {
    constructor() {
        super([
            { name: 'tk-manager', constructor: NSManager },
            { name: 'tk-viewport', constructor: TKViewport },
            { name: 'tk-header', constructor: TKHeader },
            { name: 'tk-nav-sidebar', constructor: TKNavigationSidebar },
            { name: 'tk-search', constructor: TKSearch },
            { name: 'tk-form-zip-city', constructor: TKFormZipCity },
            { name: 'tk-form-element', constructor: NSFormElement },
            { name: 'tk-form-validator', constructor: NSFormValidator },
            { name: 'tk-tabs-tab', constructor: TKTabsTab },
            { name: 'tk-minibasket', constructor: TKMinibasket },
            { name: 'tk-tabs-accordion', constructor: TKTabsAccordion },
            // NS-53: Compare f�r Phase 1 auskommentiert
            // { name: 'tk-compare-list-link', constructor: TKCompareListLink },
            { name: 'tk-dialog', constructor: TKDialog },
            { name: 'ns-image', constructor: NSImage },
        ]);
    }
}

// eslint-disable-next-line no-new
new Verification();
